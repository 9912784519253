import React from "react";
import Navbar from "../component/Navbar";
import AboutHero from "../images/lagosmini.png"
import Footer from "../component/Footer";

function About() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <section style={{ backgroundImage: `url(${AboutHero})` }} className="h-80 bg-cover bg-right sm:bg-center bg-no-repeat w-full align-middle my-auto justify-center flex flex-col sm:px-14 px-2 text-white">
        <h3 className="text-xl font-thin">Our Company</h3>
        <h1 className="text-5xl">Welcome to <br/>Thom-Lee Global Services</h1>

      </section>

      <section>
        <div className="w-[90%] mx-auto flex ">
          <div className="w-[40%]">
            <img src={require('../images/office.jpeg')} alt="" />
            <p className="text-[#003260] font-thin">Thom-Lee Global Services Headquaters</p>
          </div>
          <div className="w-[60%] p-2 sm:p-0 flex flex-col sm:justify-center sm:align-middle sm:items-center text-xl sm:space-y-10 text-left">
            <div className="flex flex-col sm:flex-row sm:space-x-40 justify-start items-start">
              <div>
                <p className="text-[#003260] font-semibold">Chairman/CEO</p>
                <p>Olaleye Thompson</p>
              </div>
              <div>
                <p className="text-[#003260] font-semibold">Active since</p>
                <p>2010</p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  sm:space-x-40 justify-start items-start">
              <div>
                <p className="text-[#003260] font-semibold">Headquaters</p>
                <p>Apapa, Lagos.</p>
              </div>
              <div>
                <p className="text-[#003260] font-semibold">Workforce</p>
                <p>50+</p>
              </div>
            </div>
            <div>
              <p className="text-[#003260] font-semibold">Services</p>
              <p>Haulage, Import, Export, General contractors.</p>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row py-14">
          <div className="sm:w-[30%]">
            <h1 className="text-3xl text-[#595959]">Our History</h1> 
            <p className="text-lg text-[#003260]">Proven track record down the years</p>
            </div>
          <p className="sm:w-[70%] leading-7">Incorporated in 2010, our company has grown to become a trusted name in the haulage, import and export, and general contracting industries. Over the years, we have established a strong reputation for reliability and excellence, which has enabled us to build lasting relationships with a diverse range of multinational clients.
            <br/><br/>From our humble beginnings, we have continually expanded our capabilities and refined our processes to meet the evolving needs of our clients. Today, we stand as a dynamic and innovative company, dedicated to providing exceptional solutions and driving success for our clients in a competitive global market.</p>
        </div>

      </section>

      <section className="border-dotted border-t-[1px] border-[#0032608f]">
        <div className="w-[90%] mx-auto py-14 ">
          <h1 className="text-3xl text-[#595959]">Our Mission</h1> 
          <p className="leading-7">Our mission is to deliver exceptional haulage, import and export, and general contracting services that exceed our clients' expectations. We are committed to ensuring timely and dependable services, maintaining the highest quality standards in all our operations, and continuously improving through innovation.<br/><br/>

          Building strong, long-term relationships with our clients is at the core of our approach, as we focus on understanding their needs and helping them achieve their goals. Our dedication to these principles drives us to be a leader in our industry and a trusted partner for businesses worldwide.<br/><br/>

              We wish to thank our valuable customers for their continued support.</p>
        </div>

      </section>

      <section>
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row py-14">
          <div className="sm:w-[30%]">
          <h1 className="text-3xl text-[#595959]">Our People</h1>
          <p className="text-lg text-[#003260]">Round the clock reliable and experienced team</p>

          </div>
          <p className="sm:w-[70%] leading-7">Our success is driven by our dedicated and talented team, who bring a wealth of knowledge and expertise to our operations. We pride ourselves on fostering a collaborative and inclusive workplace where innovation and excellence thrive. Continuous training and professional development are core to our approach, ensuring our team stays ahead of industry trends. <br/><br/> Each member, from our skilled drivers to our expert project managers, plays a crucial role in delivering exceptional service. Our commitment to our people translates into outstanding results and satisfaction for our clients.</p>

        </div>

      </section>

      <Footer/>
    </div>
  );
}

export default About;
