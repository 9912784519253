import React from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
import Crane from '../images/crane.jpg'

function GetQuotation() {
    return (
        <div>
        <Navbar/>
        <section className=''>

        <div style={{ backgroundImage: `url(${Crane})` }} className="h-72 bg-cover bg-center bg-no-repeat w-full  flex ">
  <div className=" h-72 w-full sm:px-12 backdrop-brightness-200 bg-black/70 content-center items-center">
  <div className="sm:w-[90%] p-2 my-auto flex flex-col sm:justify-between">
    <p className="cursor-pointer duration-500 text-lg text-white">Ge a Quotation</p>
    <h1 className=" text-white font-medium text-5xl">We are always here<br/> at your service.</h1>

  </div>

  </div>
</div>

      <div className='w-full px-2 sm:px-0 sm:w-[65%] mx-auto py-5 sm:py-14 flex flex-col sm:flex-row gap-x-10 divide-x-[1px] text-[#003260]'>
        <div className='py-2 sm:py-0'>
          <p className='font-thin text-3xl'>Get a Quote</p>
          <h1>We would give you an estimate in no time!</h1>
        </div>

        <form className='px-5'>
          <div className="space-y-3">


            <div className="border-b border-gray-900/10 py-6 sm:pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-full">
                <div className="sm:col-span-full">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-full">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Port
                  </label>
                  <div className="mt-2">
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>Tin Can</option>
                      <option>Lekki</option>
                      <option>APMT</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Weight
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="weight"
                      id="weight"
                      placeholder='eg. 1234kg'
                      autoComplete="weight"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:max-w-xs sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Delivery address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={''}
                    />
                  </div>
                </div>


          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-[#003260] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#003260]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>

      </div>
    </section>
        <Footer/>
        </div>)
}

export default GetQuotation