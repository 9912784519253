import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { useNavigate } from 'react-router-dom'

function Services() {
  const navigate = useNavigate()

  return (
    <div>
      <Navbar/>
        <section>
          <div className='grid sm:grid-cols-2 gap-x-[%] w-[90%] mx-auto '>

            <div className='flex flex-col justify-between gap-y-8 px-14 py-4 border-dotted border-[1px] border-[#0032608f] hover:bg-[#003260] hover:text-white'>
              <div>
                <p className=' text-base'>Logistics</p>
              </div>
              <div className='text-base pt-5'>
              <h1 className='sm:text-2xl font-semibold'>HAULAGE & DELIVERY </h1>
                <p className='font-light'>Our Haulage & Delivery services ensure the safe and timely transportation of goods across various locations. With a fleet of modern vehicles and experienced drivers, we handle everything from small packages to large shipments with utmost care and efficiency. We are committed to providing reliable and cost-effective solutions that meet the unique needs of each client.

</p>
              </div >
                <p className='cursor-pointer underline'
                onClick={()=> navigate('/get-a-quotation')}
                >Enquiry</p>
            </div>

            <div className='flex flex-col justify-between gap-y-8 px-14 py-4 border-dotted border-[1px] border-[#0032608f] hover:bg-[#003260] hover:text-white'>
              <div>
                <p className=' text-base'>Logistics</p>
              </div>
              <div className='text-base pt-5'>
              <h1 className='sm:text-2xl font-semibold'>IMPORT</h1>

                <p className='font-light'>Our import services streamline the complex process of bringing goods from international markets to your doorstep. We handle every aspect, from customs clearance to logistics management, ensuring timely and efficient delivery. Our expertise and global network guarantee a seamless import experience for our clients.</p>
              </div>
                <p className='cursor-pointer underline'
                onClick={()=> navigate('/get-a-quotation')}
                >Enquiry</p>
            </div>
            <div className='flex flex-col justify-between gap-y-8 px-14 py-4 border-dotted border-[1px] border-[#0032608f] hover:bg-[#003260] hover:text-white'>
              <div>
                <p className=' text-base'>Logistics</p>
              </div>
              <div className='text-base pt-5'>
              <h1 className='sm:text-2xl font-semibold'>EXPORT</h1>

                <p className='font-light'>Our export services are designed to facilitate the smooth and efficient global distribution of goods. We handle all aspects of the export process, from documentation and customs clearance to logistics and transportation. With our expertise and extensive network, we ensure timely and reliable delivery for international shipments. </p>
              </div>
                <p className='cursor-pointer underline'
                onClick={()=> navigate('/get-a-quotation')}
                >Enquiry</p>
            </div>
            <div className='flex flex-col justify-between gap-y-8 px-14 py-4 border-dotted border-[1px] border-[#0032608f] hover:bg-[#003260] hover:text-white'>
              <div>
                <p className=' text-base'>Logistics</p>
              </div>
              <div className='text-base pt-5'>
              <h1 className='sm:text-2xl font-semibold'>GENERAL CONTRACTORS </h1>

                <p className='font-light'>Our General Contracting services encompass a wide range of construction and renovation projects, ensuring top-quality workmanship and timely completion. We manage every aspect of the project, from initial planning and procurement to execution and final delivery. Our team's expertise and dedication guarantee exceptional results, tailored to meet our clients' specific needs.</p>
              </div>
                <p className='cursor-pointer underline'
                onClick={()=> navigate('/get-a-quotation')}
                >Enquiry</p>
            </div>



  

          </div>
        </section>
      
      <Footer/>
      </div>
  )
}

export default Services