import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import HeroImg from "../images/citysea.jpg"
import Bulk from "../images/bulk-loading.jpg"
// import Container from "../images/containers.jpg"
import Transport from "../images/transport.jpg"
import Light from "../images/nightlight.jpg"
import Footer from "../component/Footer";
import Loader from "../component/Loader";
import { useNavigate } from "react-router-dom";




function Home() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  

  return (
    <div className="bg-white h-screen font-Inter">
      <Navbar />

      <section className="relative ">
  <div
    className="relative h-[20rem] sm:h-[30rem] w-full bg-cover bg-left bg-no-repeat z-0 mix-blend-multiply"
    style={{ backgroundImage: `url(${HeroImg})` }}
      
  >

  </div>
  {/* <div className="absolute top-0 right-0 bg-slate-500 mix-blend-multiply h-[20rem] sm:h-[30rem] w-full z-10"></div>
  <div className="absolute top-0 left-0  w-full flex pt-10 sm:pt-0 align-middle items-center content-center  justify-start z-20 px-2 sm:px-10">
    <h2 className="text-white text-4xl sm:text-6xl sm:w-[50%]">Top logistics and supply chain management  providers</h2>
  </div> */}
    <div className="absolute top-0 right-0 bg-slate-500 mix-blend-multiply h-[20rem] sm:h-[30rem] w-full z-10"></div>
  <div className="absolute top-0 left-0 h-full w-full flex pt-5 sm:pt-0  items-center justify-start z-20 px-2 sm:px-10">

      <div>
    <h2 className="text-white text-4xl sm:text-6xl sm:w-[55%]">Reliable Haulage and supply chain management service providers across Nigeria</h2>
    <p className="text-xl py-5 text-slate-200">Timely, Secure, and Efficient</p>
    <button className="underline underline-offset-4 hover:underline-offset-8 duration-500 text-white  rounded p-2 font-medium 
"
onClick={()=> navigate('/get-a-quotation')}
>Get a Quotation</button>
{/* hover:bg-black hover:text-white bg-white text-black */}
      </div>
      {/* hover:bg-black hover:text-white */}
  </div>  
</section>

<section className="my-10">
  <div className="w-full px-2 sm:px-0 sm:w-[90%] mx-auto">
    <h1 className="text-xl sm:text-3xl py-2 sm:py-5 text-[#595959]">At your service</h1>
    <p className="text-base sm:text-base text-[#003260]">Incorporated since 2010 ......
      We are experienced haulage company - Reliable, secure. Transpoters of all contanier types  all across Nigeria.</p>
    <p className="underline underline-offset-2 decoration-1 text-blue-700 cursor-pointer"
    onClick={()=> navigate('/about')}
    >Learn more</p>
  </div>
</section>

<section className="py-4 sm:py-14 text-[#003260] border-dotted border-b-[1px] border-[#0032608f]">
  <div className="flex flex-col sm:flex-row justify-center gap-y-5 gap-x-5 sm:gap-x-10">
    <div className="text-center">
      <p className="text-3xl font-bold sm:text-5xl ordinal slashed-zero font-tektur tabular-nums">3,000+</p>
      <h1 className="text-xl text-[#595959]">Successful deliveries</h1>
    </div>
    <div className="text-center">
      <p className="text-3xl font-bold sm:text-5xl ordinal slashed-zero font-tektur tabular-nums">36<span> states</span></p>
      <h1 className="text-xl text-[#595959]">Delivery Destinations</h1>
    </div>
    <div className="text-center">
      <p className="text-3xl font-bold sm:text-5xl ordinal slashed-zero font-tektur tabular-nums">36<span> states</span></p>
      <h1 className="text-xl text-[#595959]">Delivery Destinations</h1>
    </div>
  </div>
</section>

<section className="py-14">
  <div className="w-full sm:w-[90%] px-2 sm:px-0 mx-auto grid grid-cols-1 sm:grid-cols-2 text-[#003260]">
    <div className="">
      <div>
        <h1 className="text-[#595959] text-2xl">Our Services</h1>
        <p className="text-base sm:text-2xl " >Nation wide service providers, all container types and bulk goods all across Nigeria.</p>
      </div>
      <div>

      <div className="hidden sm:flex flex-col gap-y-2">
          <div>
            <img className="w-80" src={Bulk} alt=""/>
            <p>Your container is in safe hands</p>
            </div>
          <div>
            <img className="w-80" src={Transport} alt=""/>
            <p>Safe delivery to you</p>
            </div>
        </div>
      </div>
    </div>

    <div className="flex flex-col gap-y-5 text-2xl sm:text-3xl font-medium">
      <div className="py-4">
        <h1 className="text-xl">Thom-Lee Global Services Limited is a leading logistic company in Nigeria.</h1>
        <p className="text-base sm:text-lg font-normal py-2">We aim to be the preferred service provider for the inter-state shipment of cargo container and related bulk shipment.</p> 
      </div>

      <div className="flex gap-x-2">
        <p className="text-[#59595988] tabular-nums">01</p>
        <p>HAULAGE & DELIVERY </p>
      </div>
      <div className="flex gap-x-2">
        <p className="text-[#59595988] tabular-nums">02</p>
        <p>IMPORT </p> 
      </div>
      <div className="flex gap-x-2">
        <p className="text-[#59595988] tabular-nums">03</p>
        <p>EXPORT </p>
      </div>
      <div className="flex gap-x-2">
        <p className="text-[#59595988] tabular-nums">04</p>
        <p>GENERAL CONTRACTORS </p>
      </div>


      <div className="flex sm:hidden flex-col gap-y-2">
          <div>
            <img className="w-80" src={Bulk} alt=""/>
            <p className="text-base font-thin">Your container is in safe hands</p>
            </div>
          <div>
            <img className="w-80" src={Transport} alt=""/>
            <p className="text-base font-thin">Safe delivery to you</p>
            </div>
        </div>
    </div>

  </div>

</section>

<section className="py-4 sm:py-14 text-[#003260] border-dotted border-t-[1px] border-[#0032608f]">
  <div className="w-full px-2 sm:px-0 sm:w-[90%] mx-auto flex">
    <div className="w-[30%]">
      <p className="text-xl sm:text-2xl text-[#595959]">Some of our Clients</p>
      <h1>We deliver for them</h1>
    </div>
    <div className="w-[70%] grid grid-cols-2 sm:grid-cols-4 gap-y-2 gap-x-10 sm:gap-y-5">
      <img className="w-32" src={require('../images/logo/dhl.png')} alt=""/>
      <img className="w-32" src={require('../images/logo/gmt-logo.jpg')} alt=""/>
      <img className="w-32" src={require('../images/logo/pnl.jpg')} alt=""/>

    </div>

  </div>
</section>

<div style={{ backgroundImage: `url(${Light})` }} className="h-72 bg-cover bg-center bg-no-repeat w-full  flex ">
  <div className=" h-72 w-full backdrop-brightness-200 bg-black/70 content-center items-center mx-auto ">
  <div className="sm:w-[90%] sm:mx-auto px-2 sm:px-0 flex flex-col sm:flex-row sm:justify-between ">
    <h1 className="text-center text-white font-semibold text-4xl">More questions to ask?</h1>
    <p className="underline underline-offset-4 cursor-pointer hover:underline-offset-8 duration-500 text-lg text-white text-center"
    onClick={()=> navigate('/contact')}
    >Learn more ways we can help</p>

  </div>

  </div>
</div>

<Footer/>

    </div>
  );
}

export default Home;

// {/* <details>
//   <summary>Lyrics</summary>
//   <p>helllllo</p>
// </details>; */}
