import React from 'react'

function Loader() {
  return (
    <div class="flex flex-col items-center justify-center h-screen">
              <div className="leading-[15px] font-serif font-extrabold text-[#003049] hover:text-[#b80f0f] flex flex-col align-middle justify-center cursor-pointer text-center py-2"
      >
        <p>THOM-LEE</p>
        <p className="whitespace-nowrap">GLOBAL SERVICES LTD</p>
      </div>

    <div class="relative">
        <div class="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
        <div class="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
        </div>
    </div>
</div>
  )
}

export default Loader