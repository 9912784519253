// import Navbar from '../component/Navbar'
// import Footer from '../component/Footer'
// import Loaded from '../images/loaded.jpg'
// export default function Contact() {
//   return (
//     <div>
//     <Navbar/>
//     <section className=''>
//     <div style={{ backgroundImage: `url(${Loaded})` }} className="h-72 bg-cover bg-center bg-no-repeat w-full  flex ">
//   <div className=" h-72 w-full sm:px-12 backdrop-brightness-200 bg-black/70 content-center items-center">
//   <div className="sm:w-[90%] p-2 my-auto flex flex-col sm:justify-between">
//     <p className="cursor-pointer duration-500 text-lg text-white">Contact Us</p>
//     <h1 className=" text-white font-medium text-5xl">We are always here to <br/> talk to you</h1>

//   </div>

//   </div>
// </div>

//       <div className='w-full px-2 sm:px-0 sm:w-[65%] mx-auto py-5 sm:py-14 flex flex-col sm:flex-row gap-x-10 divide-x-[1px] text-[#003260]'>
//         <div>
//           <p className='font-thin text-3xl'>Contact Form</p>
//           <h1>We can't wait to hear from you</h1>
//         </div>

//         <form className='px-5'>
//           <div className="space-y-3">


//             <div className="border-b border-gray-900/10 py-6">
//               <h2 className="text-base font-thin leading-7 text-gray-900">Client Information</h2>
//               <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

//               <div className="mt-10 grid grid-cols-1 gap-x-3 gap-y-2 sm:grid-cols-6">
//                 <div className="sm:col-span-full">
//                   <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
//                     Name
//                   </label>
//                   <div className="mt-2">
//                     <input
//                       type="text"
//                       name="name"
//                       id="name"
//                       autoComplete="given-name"
//                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                     />
//                   </div>
//                 </div>

//                 <div className="sm:col-span-4">
//                   <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
//                     Email address
//                   </label>
//                   <div className="mt-2">
//                     <input
//                       id="email"
//                       name="email"
//                       type="email"
//                       autoComplete="email"
//                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="sm:col-span-4">
//                   <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
//                     Subject
//                   </label>
//                   <div className="mt-2">
//                     <input
//                       id="subject"
//                       name="subject"
//                       type="subject"
//                       autoComplete="subject"
//                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                     />
//                   </div>
//                 </div>

//             <div className="col-span-full">
//                   <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
//                     Message
//                   </label>
//                   <div className="mt-2">
//                     <textarea
//                       id="message"
//                       name="message"
//                       rows={3}
//                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                       defaultValue={''}
//                     />
//                   </div>
//                 </div>


//           </div>

//           <div className="mt-6 flex items-center justify-end gap-x-6">
//             <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="rounded-md bg-[#003260] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#003260]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//             >
//               Send
//             </button>
//           </div>
//         </form>

//       </div>
//     </section>
//     <Footer/>
//     </div>)
// }

import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from '../component/Navbar';
import Loaded from '../images/loaded.jpg';
import Footer from '../component/Footer';

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs.sendForm('service_ro5fjwj', 'template_fnmdfni', form, 'PaUKTiOEglG-mvxtX')
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        alert('Contact form submitted successfully!');

        // Clear the form fields after successful submission
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      }, (error) => {
        console.log('Failed to send email.', error.text);
        alert('Failed to submit contact form. Please try again.');
      });
  };

  return (
    <div>
      <Navbar />
      <section className='bg-[#EDEDED]'>
        <div style={{ backgroundImage: `url(${Loaded})` }} className="h-72 bg-cover bg-bottom bg-no-repeat w-full  flex ">
          <div className="h-72 w-full sm:px-12 backdrop-brightness-200 bg-black/70 content-center items-center">
            <div className="sm:w-[90%] p-2 my-auto flex flex-col sm:justify-between">
              <p className="cursor-pointer duration-500 text-lg text-white">Contact Us</p>
              <h1 className="text-white font-medium text-5xl">We are always here to <br /> talk to you</h1>
            </div>
          </div>
        </div>
        <div className='w-full px-2 sm:px-0 sm:w-[65%] mx-auto py-5 sm:py-14 flex flex-col sm:flex-row gap-x-10 divide-x-[3px] text-[#003260]'>
          <div>
            <p className='font-thin text-3xl'>Contact Form</p>
            <h1>We can't wait to hear from you</h1>
          </div>
          <form onSubmit={handleSubmit} className='px-5'>
            <div className="space-y-3">
              <div className="border-b border-gray-900/10 py-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
                <div className="mt-10 grid grid-cols-1 gap-x-3 gap-y-2 sm:grid-cols-6">
                  <div className="sm:col-span-full">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="given-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                      Subject
                    </label>
                    <div className="mt-2">
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        autoComplete="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                      Message
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-[#003260] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#003260]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
