import React from 'react'
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate()
  return (
    <div>
      <section className="bg-[#003049] w-100vw px-2 sm:px-0">
        <div className="flex flex-col  sm:flex-row sm:w-[90%] justify-between mx-auto  items-center py-16">
          <div className=" flex flex-col gap-4">
          <div className="flex cursor-pointer space-x-2"
      >
        <div>
          <img className="w-10 h-10" src={require('../images/logo/darkT.png')}/>
        </div>
        <div className="leading-[15px] font-serif font-extrabold text-white hover:text-[#C1A05E] flex flex-col align-middle justify-center">
        <p>THOM-LEE</p>
        <p className="whitespace-nowrap">GLOBAL SERVICES LTD</p>
        </div>
      </div>

            <div className=" sm:w-[70%] flex-col flex sm:flex-row text-base sm:gap-6 gap-3 text-[#94a0b0]">
              <h1 className="">
                Lagos Office: 41,CALCUTA CRESCENT, APAPA. 
                LAGOS STATE, NIGERIA.
              </h1>

            </div>

            <div className="flex flex-col gap-4 text-base text-[#94a0b0]">
              <h1 className="">08164718606, 08174462329, 08107772791</h1>
              <p className="">
                Copyright © 2024 Salesforce. All rights reserved.
              </p>
            </div>
          </div>

          <div className='flex mx-auto justify-center align-middle content-center'>

            <iframe className='w-96 h-96' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6448318647094!2d3.364708105990731!3d6.439636929744111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8bb34dd10a79%3A0xdc08d43802dd81!2s41%20Calcutta%20Cres%2C%20Apapa%20Quays%2C%20Lagos%20102272%2C%20Lagos!5e0!3m2!1sen!2sng!4v1720766158015!5m2!1sen!2sng" width="600" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Google Map'></iframe>
            </div>

          <div>
            <p className='whitespace-nowrap underline underline-offset-4 hover:underline-offset-8 duration-50000 text-white cursor-pointer'
            onClick={()=> navigate('/contact')}
            >Contact Us</p>
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer